import { omit } from 'lodash';
import { getProjects, getProjectTotals } from '../../../HttpRequests/projects';
import { loadProjects } from '../State/projects';
import { loadProjectTotals } from '../State/projectTotals';
import { setFetching } from '../State/fetching';
import { setPagination } from '../State/pagination';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.Projects;

  try {

    const params = {};
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    params.include = [
      'contractsManager',
      'otherContractsManager',
      'supervisor',
      'latestValuations',
    ].join()

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }

    if (filters.phase_key.length > 0) {
      params['filter[phase_key]'] = filters.phase_key.join(',');
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.contracts_manager.length > 0) {
      params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
    }

    if (filters.supervisor_id.length > 0) {
      params['filter[supervisor_id]'] = filters.supervisor_id.join(',');
    }

    if (filters.no_valuation_this_month) {
      params['filter[no_valuation_this_month]'] = 1
    }

    if (filters.no_start_handover_date) {
        params['filter[no_start_handover_date]'] = 1
    }

    dispatch(setFetching(true));

    const response = await getProjects({ params });

    dispatch(loadProjects(response.data.data));

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

    const response2 = await getProjectTotals({ params: omit(params, ['page[size]', 'page[number]', 'csort']) });

    dispatch(loadProjectTotals(response2.data.data));

  } catch (error) {

    handleException(error);

  }
}
