import { createAction, handleActions } from 'redux-actions'
import { concat } from 'lodash'
import chance from 'chance'

const setJobs = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/SET_JOBS')
const addJob = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/ADD_JOB')
const updateJob = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/UPDATE_JOB')
const removeJob = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/REMOVE_JOB')
const resetJobs = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/RESET_JOBS')

const defaultState = []

const reducer = handleActions(
  {
    [setJobs]: (state, action) => action.payload,
    [addJob]: (state, { payload }) => concat(state, {
      key: chance().guid(),
      id: null,
      subcontractor_id: null,
      slot: payload,
      quantity: 1,
      price_work: false,
      confirmed: true,
      subcontractor: {},
    }),
    [updateJob]: (state, { payload }) => [...state].map(job => {

      if (job.key !== payload.key) return job
      return { ...job, ...payload.data }
    }),
    [removeJob]: (state, { payload }) => [...state].filter(job => job.key !== payload.key),
    [resetJobs]: () => defaultState,
  },
  defaultState,
)

export {
  setJobs,
  addJob,
  updateJob,
  removeJob,
  resetJobs,
  reducer,
}
