import axios from './axios';

export const getProjects = ({ params } = {}) => axios.get(`projects`, { params });
export const getProject = ({id, params }) => axios.get(`projects/${id}`, { params });
export const createProject = ({ data }) => axios.post('projects', data);
export const updateProject = ({ id, data }) => axios.put(`projects/${id}`, data);
export const deleteProject = ({ id }) => axios.delete(`projects/${id}`);
export const completeProject = ({  id, data }) => axios.put(`projects/${id}/complete`, data);
export const uncompleteProject = ({  id, data }) => axios.put(`projects/${id}/uncomplete`, data);
export const getProjectTotals = ({ params } = {}) => axios.get(`projects/totals`, { params });
export const getProjectTotalsPost = ({ params } = {}) => axios.post(`projects/totals`, { params });

export const updateTargetCosts = ({ data }) => axios.put(`projects/update_target_costs`, data);
export const prepareProjectInformationEmail = ({ id }) => axios.get(`projects/${id}/prepare_project_information_email`);
export const sendProjectInformationEmail = ({ id, data }) => axios.post(`projects/${id}/send_project_information_email`, data);
