import React from 'react'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Screen from '../../Components/Screen'

import Controls from './Components/Controls'
import ProjectTable from './Components/ProjectTable'
import Gantt from './Components/Gantt'
import ProjectTableShrunk from './Components/ProjectTableShrunk'
import ProjectSummaryTable from './Components/ProjectSummaryTable'
import SubcontractorTable from './Components/SubcontractorTable'
import ControlsModal from './Components/ControlsModal'
import ProjectEditorModal from '../../Modals/Project'
import ProjectCellModal from './Components/ProjectCellModal'
import SubcontractorEditorModal from '../../Modals/Subcontractor'
import SubcontractorCellModal from './Components/SubcontractorCellModal'
import SubcontractorDrawer from './Components/SubcontractorDrawer'
import Footer from './Components/Footer';
import SubcontractorModal from "../../Modals/Subcontractor";

export default class Planner extends React.PureComponent {

  static propTypes = {
    yAxis: PropTypes.string.isRequired,
    summary: PropTypes.bool.isRequired,
    shrink: PropTypes.bool.isRequired,
    controlsModal: PropTypes.object.isRequired,
    projectEditorModal: PropTypes.object.isRequired,
    projectCellModal: PropTypes.object.isRequired,
    subcontractorEditorModal: PropTypes.object.isRequired,
    subcontractorCellModal: PropTypes.object.isRequired,
    subcontractorDrawer: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseControlsModal: PropTypes.func.isRequired,
    onCloseProjectEditorModal: PropTypes.func.isRequired,
    onCloseProjectCellModal: PropTypes.func.isRequired,
    onCloseSubcontractorEditorModal: PropTypes.func.isRequired,
    onCloseSubcontractorCellModal: PropTypes.func.isRequired,
    onCloseSubcontractorDrawer: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func.isRequired,
    onHorizontalScroll: PropTypes.func.isRequired,
    projectTotals: PropTypes.object.isRequired,
    showSubcontractorModal: PropTypes.bool.isRequired,
  }

  componentDidMount = () => {
    this.props.componentDidMount()

    let fired = false
    document.addEventListener('keydown', e => {
      if (!fired) {
        fired = true
        this.props.onKeyDown(e.key)
      }
    })
    document.addEventListener('keyup', () => {
      fired = false
      this.props.onKeyUp()
    })

    /*
    let wheeling = false
    document.addEventListener('mousewheel', e => {
      if (!wheeling) {
        if (e.deltaX < 0) this.props.onHorizontalScroll('left')
        if (e.deltaX > 0) this.props.onHorizontalScroll('right')
      }
      clearTimeout(wheeling)
      wheeling = setTimeout(() => {
        wheeling = false
      }, 250)
    })
    */
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', null)
    document.removeEventListener('keyup', null)
    document.removeEventListener('mousewheel', null)
  }

  render() {

    const {
      yAxis,
      summary,
      shrink,
      controls,
      ganttSettingDuration,
      controlsModal,
      projectEditorModal,
      projectCellModal,
      subcontractorEditorModal,
      subcontractorCellModal,
      subcontractorDrawer,
      onCloseControlsModal,
      onCloseProjectEditorModal,
      onCloseProjectCellModal,
      onCloseSubcontractorEditorModal,
      onCloseSubcontractorCellModal,
      onCloseSubcontractorDrawer,
      projectTotals,
      showSubcontractorModal,
    } = this.props

    return (

      <Screen>

        <DndProvider backend={HTML5Backend}>

          <Controls />

            {(yAxis === 'gantt' && ganttSettingDuration == "Week") && <Gantt ganttSettingDuration="Week" />}
            {(yAxis === 'gantt' && ganttSettingDuration == "Day") && <Gantt ganttSettingDuration="Day" />}
            {(yAxis === 'gantt' && ganttSettingDuration == "Month") && <Gantt ganttSettingDuration="Month" />}

            {yAxis === 'projects' && !summary && !shrink && <ProjectTable />}

            {yAxis === 'projects' && !summary && shrink && <ProjectTableShrunk />}

            {yAxis === 'projects' && summary && !shrink && <ProjectSummaryTable />}

            {yAxis === 'subcontractors' && <SubcontractorTable />}

          {controlsModal.show && (
            <ControlsModal
              {...controlsModal}
              onClose={onCloseControlsModal}
            />
          )}

          {projectEditorModal.show && (
            <ProjectEditorModal
              {...projectEditorModal}
              planner
              onClose={onCloseProjectEditorModal}
            />
          )}

          {projectCellModal.show && (
            <ProjectCellModal
              {...projectCellModal}
              onClose={onCloseProjectCellModal}
            />
          )}

          {subcontractorEditorModal.show && (
            <SubcontractorEditorModal
              {...subcontractorEditorModal}
              onClose={onCloseSubcontractorEditorModal}
            />
          )}

          {subcontractorCellModal.show && (
            <SubcontractorCellModal
              {...subcontractorCellModal}
              onClose={onCloseSubcontractorCellModal}
            />
          )}

          {subcontractorDrawer.show && (
            <SubcontractorDrawer
              {...subcontractorDrawer}
              onClose={onCloseSubcontractorDrawer}
            />
          )}

        </DndProvider>
          {(yAxis === 'gantt') &&
              <Footer
                  projectTotals={projectTotals}
              />
          }

          {showSubcontractorModal && (
              <SubcontractorModal
                  subcontractor={subcontractor}
              />
          )}

      </Screen>

    )

  }

}
